import React from 'react';
import { event } from './src/analytics/gtm';
import { AlertProvider } from './src/hooks/useAlertContext';
import './src/styles/index.scss';

const siteBranchName = process.env.BRANCH;

const sendCurrentBranchEvent = () => {
  event('event_track_branch', {
    branch: `site_${siteBranchName}`,
  });
};

const scroll = () => {
  document.body.scrollTop = 0;
};

const initScripts = () => {
  sendCurrentBranchEvent();
};

export const shouldUpdateScroll = scroll;
export const onClientEntry = initScripts;

export const wrapRootElement = ({ element }) => {
  return <AlertProvider>{element}</AlertProvider>;
};
